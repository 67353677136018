<template>
  <v-dialog persistent :value="true" width="100%" max-width="500">
    <v-card>
      <v-card-title class="text-h5 ap-orange ap-white--text">
        Authentication
      </v-card-title>

      <v-card-text>
        <div class="text-body-1 font-weight-medium mt-5">
          Your session expired!
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn color="ap-orange" text @click="signOut"> Sign Out </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    signOut() {
      localStorage.removeItem('env')
      localStorage.removeItem('authJson')
      localStorage.removeItem('is_new_dupegrid_enabled')
      this.$router
        .push({ name: 'dev-root', query: { reset: true } })
        .catch(() => {})
    },
  },
}
</script>
